import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Logo } from '../components/components';
import './MainScaffold.css';

export function MainScaffold() {
    return (
        <div className='MainScaffold-1'>
            <div className='MainScaffold-top'>
                <div className='MainScaffold-menu'>
                    <Logo width="115" />
                    <div className='MainScaffold-logo-spacer' />
                    <NavLink to="/" className={({isActive}) => "MainScaffold-menu-item"+ (isActive ? " active" : "")}>my panels</NavLink>
                    <NavLink to="/list" className={({isActive}) => "MainScaffold-menu-item"+ (isActive ? " active" : "")}>find new panels</NavLink>

                </div>

            </div>
            <div className='MainScaffold-body'>
                <Outlet />

            </div>

        </div>
    );
}