import './AlertMessage.css';

import React from 'react';


export function AlertMessage(props: any) {
    if (props.message) {
        return (
            <div className="AlertMessage active">
                <div>
                    {props.message}
                </div>
            </div>
        )

    } else {
        return (
            <div className="AlertMessage">
            </div>
        )

    }

}
