import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/Auth';
import { PanelIcon } from '../components/PanelIcon';
import { BASE_URL } from '../const';
import { configureHeader } from '../tools';
import QRCode from 'react-qr-code';
import './PanelList.css';

export function PanelList() {

    const [loading, setLoading] = useState(false);
    let auth = useAuth();
    const [panelList, setPanelList] = useState<any[]>([]);
    const navigate = useNavigate();

    const [qrshown, setQrshown] = useState(false);
    const [qr, setQr] = useState<any>({});


    useEffect(() => {
        setLoading(true);
        //get from DB
        axios.get(BASE_URL + "panels/list", configureHeader(auth.token))
            .then((response) => {
                setLoading(false);
                if (response && response.data && Array.isArray(response.data)) {
                    setPanelList(response.data);
                }
            })
            .catch(e => {
                alert("Couldn't fetch panel from database!");
            });

    }, [])



    var qrString;
    if (qr && qr.id && qr.content) {
        qrString = Buffer.from(JSON.stringify({
            "version": 1.0,
            "panel": qr.id,
            "genes": qr.content.map((eachGene: any) => {
                return {
                    "id" : eachGene.id,
                    "name": eachGene.name,
                    "range": eachGene.range,
                }
            })
        })).toString('base64');

    } else {
        qrString = "";
    }


    return (
        <div className='PanelList'>
            <div className={'PanelQR' + (qrshown ? " shown" : "")} onClick={() => {
                setQrshown(false);
            }}>
                <div className='code'>
                    <QRCode value={qrString} />
                    
                </div>
                <div className='info'>Click anywhere to close</div>

            </div>
            <div className='PanelList-create-button'>
                <button onClick={() => {
                    navigate("/edit/new");
                }}>Create panel</button>

            </div>

            <div className="PanelList-list">
                {loading && <PanelLoading />}
                {loading && <PanelLoading delay="200ms" />}
                {loading && <PanelLoading delay="400ms" />}
                {!loading && panelList.length === 0 && <div className='empty-list'>
                    <h3>Let's start creating some panels!</h3>
                    <EmptyMessage />
                    <div className='message'>There is nothing here!<br />Use the <button onClick={() => {
                        navigate("/edit/new");
                    }}>Create panel</button> button to start</div>
                </div>}
                {panelList.map((panel, index) => <PanelIcon key={index} panel={panel} onQrCode={() => {
                    setQrshown(true);
                    setQr(panel);
                }} />)}
                <div className='empty'></div>

            </div>
        </div>
    )
}

function PanelLoading(props: any) {
    return (<div className='PanelLoading' style={{ animationDelay: props.delay }}></div>)
}

function EmptyMessage() {
    return (
        <div className='EmptyMessage'>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ maxWidth: "80vw", maxHeight: "40vh" }} viewBox="0 0 1047.787 1051.002">
                <g id="Layer_2" data-name="Layer 2" transform="translate(0.001 1.211)">
                    <g id="vector" transform="translate(-0.001 3)">
                        <path id="Path_141" data-name="Path 141" d="M841.8,122.016c98.065,78.444,166.227,196.37,159.533,305.909-6.694,109.308-88.495,210.229-150.444,326.723S746.375,1003.637,664.8,1037.6c-81.79,33.971-202.824-30.614-319.066-83.474-116.243-53.091-227.7-94.707-289.164-176.99-61.479-82.042-73.434-205-33.009-298.5s132.988-157.86,218.847-238.468c86.11-80.6,165.495-177.23,269.323-206.171S743.5,43.562,841.8,122.016Z" transform="translate(0.001 -2.018)" fill="#f0f0f0" />
                        <path id="Path_142" data-name="Path 142" d="M145.759,3C47.851,79.624,84.291,389.925,285.033,429.074,520.123,474.916,536.011,234.9,714.245,256.425c154.994,18.732,201.516,186.33,105.543,291.423" transform="translate(4.299 -3)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" strokeDasharray="36" />
                        <path id="Path_143" data-name="Path 143" d="M353.015,516.664c-11.505-4.4-68.947-50.748-73.423-58.059s-34.2-53.781-34.2-53.781l50.037-38.155s45.079,40.519,53.854,48.677,42.684,21.18,46.365,21.169S353.015,516.664,353.015,516.664Z" transform="translate(11.268 13.698)" fill="#fff" />
                        <path id="Path_144" data-name="Path 144" d="M339.993,502.02c-6.861-3.954-62.106-49.89-67.556-55.329s-27.194-40.058-30.687-48.332" transform="translate(11.101 15.153)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_145" data-name="Path 145" d="M708.4,805.31c-11.777,5.23-222.957,53.666-259.6,54.105,8.441,47.254,39.8,139.7,41.993,149.085,18.753-4.55,247.17-90.472,255.632-96.109C739.407,898.867,712.276,832.8,708.4,805.31Z" transform="translate(20.607 33.837)" fill="#3b3744" />
                        <rect id="Rectangle_102" data-name="Rectangle 102" width="621.618" height="168.842" rx="80.72" transform="translate(9.893 353.84) rotate(-28.4)" fill="#d7e0ff" />
                        <path id="Path_146" data-name="Path 146" d="M285.176,317.491,242.22,303.706l13.8-42.966" transform="translate(11.122 8.834)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <path id="Path_147" data-name="Path 147" d="M351.68,211.26l42.956,13.8-13.785,42.956" transform="translate(16.148 6.562)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <line id="Line_1" data-name="Line 1" x2="30.896" y2="127.769" transform="translate(307.196 213.889)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <path id="Path_148" data-name="Path 148" d="M260.489,212.149c-8.022-17.456-58.121.962-79.489,18.732,5.941,19.527,11.976,36.712,15.417,40.613C200.632,271.933,263.125,240.3,260.489,212.149Z" transform="translate(8.311 6.28)" fill="#fff" />
                        <path id="Path_149" data-name="Path 149" d="M480.3,467.438c-29.829-24.056-70.923-40.163-82.8-45.068-8.639,3.734-19.747,23.46-29.537,45.842-17.509,32.873-23.01,72.827-23.01,72.827,27.11,16.85,83.349,27.612,93.264,27.612S480.3,467.438,480.3,467.438Z" transform="translate(15.839 16.255)" fill="#46dbc9" />
                        <path id="Path_150" data-name="Path 150" d="M533.159,441.385c104.884-25.154,152.818,275.253,175.6,379.667-22.309,16.609-220.207,66.436-259.6,54.053C376.554,683.891,351.358,484.979,533.159,441.385Z" transform="translate(18.112 17.06)" fill="#46dbc9" />
                        <path id="Path_151" data-name="Path 151" d="M525.159,431.385c104.884-25.154,152.818,275.253,175.6,379.667-22.309,16.609-220.207,66.436-259.6,54.053C368.554,673.891,343.358,474.979,525.159,431.385Z" transform="translate(17.744 16.6)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <path id="Path_152" data-name="Path 152" d="M594.557,634.09c-5.5,7.959-55.716,64.6-63.675,68.57s-39.535,3.462-45.612,0c-35.509-20.207-150-198.075-158.278-214.914s-20.2-40.707-30.917-49.587C294.349,425.1,303.71,408,287.09,387.307c3.054-14.58-5.428-79.008,42.882-53.07,12.718,6.955,30.206,34.222,27.79,56.678s-5.334,47.966-.5,58.446S493.334,599.544,496.723,601.238c4.184-5.47,20.845-27.319,20.845-27.319Z" transform="translate(13.182 11.929)" fill="#fff" />
                        <path id="Path_153" data-name="Path 153" d="M634.266,478.15c50.748,40.226-10.972,133.48-33.825,159.073-10.459.46-101.307-53.028-105.961-74.971C507.648,542.6,577.714,433.312,634.266,478.15Z" transform="translate(22.704 18.325)" fill="#46dbc9" />
                        <path id="Path_154" data-name="Path 154" d="M361.018,536.016a157.938,157.938,0,0,1-23.01-11.369c-.617-2.5.335-8.1,3.4-18.617" transform="translate(15.512 20.096)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_155" data-name="Path 155" d="M360.86,454.047c9.79-22.383,19.946-39.943,28.585-43.677,11.882,4.905,51.082,19.548,80.912,43.562" transform="translate(16.569 15.704)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <path id="Path_156" data-name="Path 156" d="M430.147,531.01c-35.655-36.23-76.143-79.249-82.868-95.262-4.487-9.413-1.318-71.227,7.2-84.478" transform="translate(15.852 12.99)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_157" data-name="Path 157" d="M496.022,559.36c-5.481,5.773-10.344,13.22-14.643,18.711-1.234-1.422-8.294-8.367-18.659-18.638" transform="translate(21.246 22.545)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_158" data-name="Path 158" d="M533.089,677.6c-4.445,4.341-7.907,7.384-9.6,8.231-7.959,3.985-39.536,3.462-45.612,0-35.509-20.207-150-198.075-158.278-214.914S299.4,430.21,288.68,421.33" transform="translate(13.255 16.207)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_159" data-name="Path 159" d="M571.665,612.57c-1.747,2.521-7.646,9.5-15.145,17.99" transform="translate(25.553 24.988)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_160" data-name="Path 160" d="M335.579,381.891c-1.046-17.781-11.589-55.611-19.569-63.8" transform="translate(14.51 11.467)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_161" data-name="Path 161" d="M318.62,381.983c-2.834-11.578-14.162-47.882-25.75-65.893" transform="translate(13.448 11.375)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_162" data-name="Path 162" d="M303.477,389.189c-.512-6.171-10.3-32.183-17.247-39.389" transform="translate(13.143 12.923)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_163" data-name="Path 163" d="M292.068,400.819c-1.286-6.432-9.267-23.679-15.438-29.6" transform="translate(12.702 13.906)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_164" data-name="Path 164" d="M724.989,901.391c-5.23-11.327-33.04-83.3-38.029-107.081" transform="translate(31.542 33.332)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <path id="Path_165" data-name="Path 165" d="M588.675,948.267c-8.367-6.8-41.094-49.158-55-68.277" transform="translate(24.504 37.266)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <path id="Path_166" data-name="Path 166" d="M480.753,995.125c-4.853-10.679-37.705-122.78-41.993-149.085" transform="translate(20.146 35.707)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <path id="Path_167" data-name="Path 167" d="M377.255,424.051C368.7,420.264,351.3,411.6,345.24,410.37" transform="translate(15.852 15.704)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_168" data-name="Path 168" d="M171.2,438.748l67.807,55.528-9.34-87.146" transform="translate(7.861 15.555)" fill="#d7e0ff" />
                        <path id="Path_169" data-name="Path 169" d="M245.509,198.316c-4.121-4.361-40.571-19.946-12.373,42.882" transform="translate(10.218 5.742)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_170" data-name="Path 170" d="M220.41,206.8c-4.184-4.361-40.571-19.946-12.384,42.882" transform="translate(9.065 6.131)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_171" data-name="Path 171" d="M190.842,220.713c-4-3.671-35.007-13.6-1.485,43.928" transform="translate(7.987 6.857)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_172" data-name="Path 172" d="M923.2,665.85l79.908-32.5L774.47,552.64l75.776,230.31,34.212-79.186" transform="translate(35.56 22.236)" fill="#d7e0ff" />
                        <path id="Path_173" data-name="Path 173" d="M918.371,660.88l89.028,90.963-38.741,37.914L879.63,698.794" transform="translate(40.388 27.206)" fill="#d7e0ff" />
                        <path id="Path_174" data-name="Path 174" d="M532.046,589.833c-22.268-15.061-42.433-31.7-44.566-41.753" transform="translate(22.383 22.027)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <path id="Path_175" data-name="Path 175" d="M639.508,536.33c-10.271,36.178-35.885,72.691-48.907,87.271-4.257.188-21.7-8.493-41.261-20.333" transform="translate(25.223 21.487)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <path id="Path_176" data-name="Path 176" d="M513.17,515.007c28.773-35.666,74.04-77.69,112.959-46.857a52.235,52.235,0,0,1,4.435,3.922" transform="translate(23.563 17.865)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <path id="Path_177" data-name="Path 177" d="M805.328,12.38,705.14,113.154,944.7,168.587Z" transform="translate(32.377 -2.569)" fill="none" stroke="#b6c6fc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.95" />
                        <rect id="Rectangle_103" data-name="Rectangle 103" width="157.933" height="179.521" transform="translate(136.62 956.327) rotate(-84.3)" fill="none" stroke="#b6c6fc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.95" />
                        <circle id="Ellipse_24" data-name="Ellipse 24" cx="86.811" cy="86.811" r="86.811" transform="translate(36.064 871.194)" fill="none" stroke="#b6c6fc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.95" />
                        <path id="Path_178" data-name="Path 178" d="M584.461,176.3c-4.518-16.337-23.617-19.81-33.25,14.643-4.184-12.07-12.917-29.223-22.027-29.223-5.69,0-13.6,5.878-19.695,16.588" transform="translate(23.394 4.287)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_179" data-name="Path 179" d="M579.4,372.07c-4.424,14.821-19.015,63.916-19.464,97.971-7.07,4.184-54.628,5.972-67.451-3.535,3.535-15.04,9.507-57.724,7.959-72.545C507.081,392.643,579.4,372.07,579.4,372.07Z" transform="translate(22.613 13.945)" fill="#fff" />
                        <path id="Path_180" data-name="Path 180" d="M493.43,419.336c1.537-13.43,2.458-26.441,1.736-33.375,6.642-1.318,78.956-21.891,78.956-21.891-4.424,14.821-19.015,63.916-19.464,97.971-4.33,2.573-23.847,4.236-41.272,2.96" transform="translate(22.656 13.578)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.3" />
                        <ellipse id="Ellipse_25" data-name="Ellipse 25" cx="86.298" cy="67.514" rx="86.298" ry="67.514" transform="translate(471.748 403.471) rotate(-77.87)" fill="#fff" />
                        <path id="Path_181" data-name="Path 181" d="M531.791,397.1a54.188,54.188,0,0,1-21.818-.178c-18.513-3.974-33.155-17.32-41.763-35.561" transform="translate(21.498 13.454)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_182" data-name="Path 182" d="M461.116,341.938a106.245,106.245,0,0,1,1.4-37.653c10.03-46.627,47.683-78.046,84.144-70.233s57.881,51.971,47.861,98.567a107.962,107.962,0,0,1-10.208,28.093" transform="translate(21.124 7.554)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_183" data-name="Path 183" d="M602.435,349.911c1.339-4.508,14.936-30.206,13.6-71.122-.868-26.922-22.142-79.793-22.09-86.811.178-22.288-23.2-32.423-34.212,6.934-4.184-12.07-12.917-29.223-22.027-29.223s-23.931,15.04-27.748,40.874c-1.266-8.263-8.681-17.781-12.917-17.781s-14.4.638-13.973,35.791c-3.389-4.236-10.8-6.778-14.643-6.778-7.865,0-18.9,37.768,12.446,62.18,1.757-3.326,6.683-10.355,6.976-10.355,5.763,1.558,61.489,15.459,95.314,20.113-.847,5.073-12.467,58.142.429,74.208A32.423,32.423,0,0,0,602.435,349.911Z" transform="translate(21.118 4.653)" fill="#fb5d64" />
                        <path id="Path_184" data-name="Path 184" d="M502.039,313.42c-1.433,5.753-10.145,15.27-14.559,16.986" transform="translate(22.383 11.253)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <ellipse id="Ellipse_26" data-name="Ellipse 26" cx="7.248" cy="4.299" rx="7.248" ry="4.299" transform="matrix(0.345, -0.938, 0.938, 0.345, 554.812, 326.391)" fill="#1b3c87" />
                        <path id="Path_185" data-name="Path 185" d="M525.565,355.12c-1.684,3.713-11.808,12.31-17.205,12.98" transform="translate(23.342 13.167)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_186" data-name="Path 186" d="M572.023,280.32a326.145,326.145,0,0,0-5.083,34.62" transform="translate(26.031 9.733)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_187" data-name="Path 187" d="M503.986,201.591c-1.266-8.263-8.681-17.781-12.917-17.781s-14.4.638-13.973,35.791c-3.389-4.236-10.8-6.778-14.643-6.778-7.865,0-18.9,37.768,12.446,62.18,1.757-3.326,6.683-10.355,6.976-10.355,4.738,1.286,43.3,10.919,75.661,16.892" transform="translate(20.844 5.302)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <path id="Path_188" data-name="Path 188" d="M594.7,327.885a159.45,159.45,0,0,0,9.089-59.387c-.492-15.04-7.321-38.186-13.294-56.9" transform="translate(27.113 6.578)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                        <circle id="Ellipse_27" data-name="Ellipse 27" cx="17.258" cy="17.258" r="17.258" transform="translate(591.214 346.762)" fill="#fff" />
                        <path id="Path_189" data-name="Path 189" d="M573.915,330.539a17.258,17.258,0,1,1-1.255,32.277" transform="translate(26.294 11.998)" fill="none" stroke="#1b3c87" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5.43" />
                    </g>
                </g>
            </svg>


        </div>
    )
}