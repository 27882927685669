import * as React from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet
} from "react-router-dom";
import { authProvider } from "./AuthProvider";
import { User } from "../types";
import { TOKEN_KEY } from "../const";

import { Loader, Logo } from '../components/components';
import './Auth.css';
import { validateEmail, validatePassword } from "../tools";
import { AlertMessage } from "../components/AlertMessage";

// export default function Auth() {
//   return (
//     <AuthProvider>
//       <h1>Auth Example</h1>

//       <p>
//         This example demonstrates a simple login flow with three pages: a public
//         page, a protected page, and a login page. In order to see the protected
//         page, you must first login. Pretty standard stuff.
//       </p>

//       <p>
//         First, visit the public page. Then, visit the protected page. You're not
//         yet logged in, so you are redirected to the login page. After you login,
//         you are redirected back to the protected page.
//       </p>

//       <p>
//         Notice the URL change each time. If you click the back button at this
//         point, would you expect to go back to the login page? No! You're already
//         logged in. Try it out, and you'll see you go back to the page you
//         visited just *before* logging in, the public page.
//       </p>

//       <Routes>
//         <Route element={<Layout />}>
//           <Route path="/" element={<PublicPage />} />
//           <Route path="/login" element={<LoginPage />} />
//           <Route
//             path="/protected"
//             element={
//               <RequireAuth>
//                 <ProtectedPage />
//               </RequireAuth>
//             }
//           />
//         </Route>
//       </Routes>
//     </AuthProvider>
//   );
// }

// function Layout() {
//   return (
//     <div>
//       <AuthStatus />

//       <ul>
//         <li>
//           <Link to="/">Public Page</Link>
//         </li>
//         <li>
//           <Link to="/protected">Protected Page</Link>
//         </li>
//       </ul>

//       <Outlet />
//     </div>
//   );
// }


interface AuthContextType {
  user: any;
  token:string;
  signin: (user: string, pass: string, callback: (success: boolean) => void) => void;
  signout: (callback: VoidFunction) => void;
  tryToken: (token: string, callback: (success: boolean) => void) => void;
  loading: boolean;
  nothingToLoad: () => void;
}





let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);
  let [loading, setLoading] = React.useState<any>(true); // set to true, so we can check for saved token without redirecting to /login
  let [token, setToken ] = React.useState<string>("");

  let signin = (username: string, password: string, callback: (success: boolean, error?:string) => void) => {
    setLoading(true);
    authProvider.signin(username, password, (success: boolean, newUser?: User, newToken?:string, error?:string) => {
      if (success) {
        if(newToken != null) {
          setToken(newToken!);

        }

        setUser(newUser);
        callback(true);
      } else {
        callback(false, error)
      }
      setLoading(false);
    });
  };

  let signout = (callback: VoidFunction) => {
    return authProvider.signout(() => {
      setUser(null);
      setToken("");
      window.localStorage.removeItem(TOKEN_KEY);
      callback();
    });
  };


  let tryToken = (token: string, callback: (success: boolean) => void) => {
    setLoading(true);
    authProvider.tryToken(token, (success, newUser) => {
      if (success) {
        setUser(newUser);
        setToken(token);
      }
      callback(success);
      setLoading(false);

    });
  };

  let nothingToLoad = () => {
    setLoading(false);

  }

  

  let value = { user, token, signin, signout, tryToken, loading, nothingToLoad };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}




export function AuthStatus() {
  let auth = useAuth();
  let navigate = useNavigate();

  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Welcome {auth.user}!{" "}
      <button
        onClick={() => {
          auth.signout(() => navigate("/"));
        }}
      >
        Sign out
      </button>
    </p>
  );
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (auth.loading === false && auth.user === null) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else if (auth.loading === true) {
    // currently in loading status
    return <Loader />
  }

  return children;
}

export function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  const [error, setError] = React.useState<string>(null!);

  let from = location.state?.from?.pathname || "/";

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;
    let password = formData.get("password") as string;
    // check for empty strings

    if (validateEmail(username) === false) {
      setError("📨 E-mail format seems wrong");
    } else if (validatePassword(password) === false) {
      setError("🔑 Password needs to be > 7 characters");

    } else {
      setError("");
      auth.signin(username, password, (success: boolean,error?:string) => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        if (success) {
          navigate(from, { replace: true });

        } else {
          // error with login
          setError(error ? error : "🔑 E-mail and password did not match");

        }
      });

    }

  }

  if (auth.user != null) {
    return (
      <div className="LoginPage">
        You are already logged in as {auth.user.name}!<br />
        <a href="javascript:;" onClick={() => auth.signout(() => {
          navigate("/");
        })}> log out </a>
      </div>
    )


  } else {
    return (
      <div className="LoginPage">
        {auth.loading && <div style={{ position: "fixed", backgroundColor: "rgba(255,255,255,0.5)" }} >
          <Loader />

        </div>}
        <Logo width="120" />

        <AlertMessage message={error} />

        <form onSubmit={handleSubmit} className="LoginPage-form">
          <label>
            <input name="username" type="text" placeholder="E-mail" />
          </label>
          <label>
            <input name="password" type="password" placeholder="Password" />
          </label>
          <button type="submit">Login</button>
        </form>
      </div>
    );

  }

}
