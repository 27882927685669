import { User } from "../types";
import axios from 'axios';
import { configureHeader } from "../tools";
import { TOKEN_KEY, BASE_URL } from "../const";
/**
 * This represents some generic auth provider API, like Firebase.
 */


function fetchMe(token: string, callback?: (success: boolean, newUser?: User, error?: string) => void) {
    // get user info using /me
    axios.get(BASE_URL + "users/me", configureHeader(token))
        .then((response) => {
            let data = response.data;
            let newUser = new User(data.name, data.id, data.email, data.doctor > 0);
            if (callback) callback(true, newUser);
        })
        .catch(e => {
            // alert('There was an error with the token. The token might have expired!');
            if (callback) callback(false, null!, "😭 Failed to get user info!");
        });
}

const authProvider = {
    isAuthenticated: false,
    token: "",
    signin(user: string, pass: string, callback: (success: boolean, user?: User, newToken?:string, error?: string) => void) {
        let data: FormData = new FormData();
        data.append("username", user);
        data.append("password", pass);
        axios.post(BASE_URL + "token", data)
            .then((response) => {
                if (response['status'] === 200) {
                    let data = response.data;

                    fetchMe(data['access_token'], (success, newUser, error?) => {
                        if (success) {
                            window.localStorage.setItem(TOKEN_KEY, data['access_token']);
                            authProvider.token = data['access_token'];
                            callback(true, newUser, authProvider.token);
                        } else {
                            window.localStorage.removeItem(TOKEN_KEY);
                            authProvider.token = "";
                            callback(false, null!, null!, error ? error : "😨 Failed to fetch user!");
                        }

                    });


                } else {
                    console.log(response);
                    callback(false, null!, null!, "😭 There was an error with server");

                }
            })
            .catch((e) => {
                if (e && e.response != null && e.response.status != null && e.response.status === 401) {//unauthorized
                    callback(false, null!, null!, "🔑 E-mail and password did not match");

                } else {
                    callback(false, null!, null!, "😭 There was an error with server");

                }
            })

    },
    signout(callback: VoidFunction) {
        authProvider.isAuthenticated = false;
        setTimeout(callback, 100);
    },



    tryToken(token: string, callback: (success: boolean, user?: User) => void) {
        fetchMe(token, (success, newUser) => {
            if (success) {
                window.localStorage.setItem(TOKEN_KEY, token);
                authProvider.token = token;
                callback(true, newUser);
            } else {
                window.localStorage.removeItem(TOKEN_KEY);
                authProvider.token = "";
                callback(false);
            }

        });
    }
};

export { authProvider };
