import React from 'react';
import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth, LoginPage, AuthStatus, RequireAuth } from './auth/Auth.tsx';
import { TOKEN_KEY } from './const';
import { MainScaffold } from './pages/MainScaffold';
import { PanelList } from './pages/PanelList';
import { PanelEdit } from './pages/PanelEdit';

function App() {
  let auth = useAuth();

  useEffect(() => {

    /* use saved token */

    let savedToken = window.localStorage.getItem(TOKEN_KEY);
    if (savedToken) {
      auth.tryToken(savedToken, (success) => {
      });
    } else {
      //set loading to false, because there's nothing to load
      auth.nothingToLoad();
    }

  }, [])

  return (
    <div className={auth.loading ? "App loading" : "App"}>
      <Routes>
        <Route path="/login" element={
          <LoginPage />

        } />
        <Route path="/edit">
          <Route path=":panelId" element={
            <RequireAuth>
              <PanelEdit />

            </RequireAuth>


          } />
        </Route>
        <Route path="/" element={<MainScaffold />}>


          <Route index element={
            <RequireAuth>
              <PanelList />
            </RequireAuth>
          } />
          <Route path="list" element={
            <RequireAuth>
              list
            </RequireAuth>
          } />
        </Route>



      </Routes>


    </div>
  );
}

export default App;
