
export class User {
    name:string;
    email:string;
    doctor:boolean;
    id:number;
    constructor(name:string, id:number, email:string = "", doctor : boolean = false) {
        this.name = name;
        this.id = id;
        this.email = email;
        this.doctor = doctor;
    }
}