export function configureHeader(token: string) {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    };

    return config;
}
export function configureJsonHeader(token:string) {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type" : `application/json`

        }
    };

    return config;

}


export function validateEmail(mail: string) {
    /**
     * returns true on valid, false on invalid;
     */
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return (true)
    }
    return (false)
}

export function validatePassword(pwd: string) {
    var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
    var mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");

    if (strongRegex.test(pwd)) {
        return true;
    } else if (mediumRegex.test(pwd)) {
        return true;
    } else {
        return false;
    }
}


export function autoReleaseOnEnter(e: any) {
    if (e.key === "Enter") {
        try {
            (document.activeElement as HTMLElement).blur();
        } catch (e) {

        }
    }
}

export function copyObject(obj:any) {
    return JSON.parse(JSON.stringify(obj));
}