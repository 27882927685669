import React, { useRef, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import './PanelEdit.css';
import { autoReleaseOnEnter, configureHeader, configureJsonHeader, copyObject } from '../tools';
import { useAuth } from '../auth/Auth';
import axios from 'axios';
import { BASE_URL } from '../const';
import { SequenceBlock } from '../components/components';

function savePanel(token: string, name: string, geneCount: number, version: number, content: any, shared: number, id: number,
    success?: () => void, error?: () => void
) {
    const data = {
        "name": name,
        "gene_count": geneCount ?? 0,
        "version": version,
        "content": content,
        "shared": shared,
        "id": id
    };
    // console.log(data);
    axios.post(BASE_URL + "panels/edit", JSON.stringify(data), configureJsonHeader(token))
        .then((response) => {
            if (success)
                success();
        }).catch(e => {
            if (error) error();
        })
}

function changeContent(content: any, index: number, key: string, value: any) {
    /* changes key-value of gene at index from total content of panel */
    let thisContent = JSON.parse(JSON.stringify(content));
    thisContent[index][key] = value;
    return thisContent;
}

export function PanelEdit(props: any) {
    let params = useParams();


    /* set panelId and isNew variables */

    let panelId: number = 0;
    let isNew: boolean = false;

    let auth = useAuth();

    const [panelName, setPanelName] = useState("");
    const [content, setContent] = useState<any[]>([]);

    const version = useRef(0);
    const shared = useRef(0);
    const initialized = useRef(false);

    const [edited, setEdited] = useState(false);
    const [loading, setLoading] = useState(false);

    const history = useRef([]);
    const navigate = useNavigate();
    /*
        history : array of {
            "name" : name,
            "content" : content,
            "version" : version,
            "shared" : shared,
        }
    */

    useEffect(() => {
        /* save only on loading == false */
        if (loading) return;
        let newHistory = copyObject(history.current);
        if (version.current === 0) return; ////////// version should always be > 0
        // if(edited) return;
        newHistory.push({
            "name": panelName,
            "content": content,
            "version": version.current,
            "shared": shared.current
        });
        history.current = newHistory;
        // console.log(history.current);
    }, [loading]);

    useEffect(() => {
        if (panelId > 0) {
            setLoading(true);
            //get from DB
            axios.get(BASE_URL + "panels/" + panelId, configureHeader(auth.token))
                .then((response) => {
                    // console.log(response.data);
                    setLoading(false);
                    if (response.data && response.data.name)
                        setPanelName(response.data.name);
                    if (response.data && response.data.content)
                        setContent(response.data.content);
                    if (response.data && response.data.version)
                        version.current = response.data.version;
                    if (response.data && response.data.shared)
                        shared.current = response.data.shared;



                })
                .catch(e => {
                    alert("Couldn't fetch panel from database!");
                    window.history.back();
                });

        } else if (isNew) {
            //new panel, so create panel
            setLoading(true);
            const sampleContent = [
                {
                    "id": "",
                    "name": "",
                    "type": "0",
                    "range": {
                        "chr": "0",
                        "end": 0,
                        "start": 0
                    },
                    "content": {
                        "known": []
                    }
                }
            ];
            const newData = {
                "name": "",
                "gene_count": 0,
                "version": 1,
                "content": sampleContent,
                "shared": 0,
                "original_id": 0,
                "original_owner_id": 0
            };
            axios.post(BASE_URL + "panels/create", JSON.stringify(newData), configureJsonHeader(auth.token))
                .then((response) => {
                    setLoading(false);
                    setContent(sampleContent);
                    navigate("/edit/" + response.data.id, { replace: true });

                }).catch((e) => {
                    alert("There was something wrong with the request!");

                });

        }

    }, [])


    if (params.panelId === "new") {
        isNew = true;
    } else {
        try {
            panelId = Number(params.panelId);
            if (!Number.isInteger(panelId)) {
                return (
                    <div>
                        Wrong panel ID!
                    </div>
                )

            }
        } catch (e) {
            return (
                <div>
                    Wrong panel ID!
                </div>
            )

        }
    }

    if (Array.isArray(content) === false) {
        setContent([]);
    }



    return (
        <div className='PanelEdit'>
            <div className='statusbar'>
                <div id="status-circle" className={loading ? 'loading' : (edited ? "editing" : "")}>
                </div>
                <div id="status-text" className={loading ? 'loading' : (edited ? "editing" : "")}>
                    {loading ? "loading" : (edited ? "edited" : "saved")}
                </div>



            </div>
            <Link to="/"><div id='CloseButton'>
                <svg xmlns="http://www.w3.org/2000/svg" width="40.688" height="40.688" viewBox="0 0 40.688 40.688">
                    <g id="Group_26" data-name="Group 26" transform="translate(-1837.66 -42.818)">
                        <path id="Path_55" data-name="Path 55" d="M7063.995,41.172l-39.981,39.981" transform="translate(-5186 2)" fill="none" stroke="#000" strokeWidth="1" />
                        <path id="Path_56" data-name="Path 56" d="M7063.995,41.172l-39.981,39.981" transform="translate(1919.167 -6980.842) rotate(90)" fill="none" stroke="#000" strokeWidth="1" />
                    </g>
                </svg>


            </div></Link>
            <div className='container'>

                {/* panel id : {panelId} */}
                <div className='PanelTitleContainer'>
                    <div>PANEL NAME</div>
                    <input type="text"
                        placeholder='Panel Name'
                        value={panelName}
                        onChange={(e) => {
                            setEdited(true);
                            setPanelName(e.target.value);
                        }}
                        onKeyDown={autoReleaseOnEnter}
                        onBlur={(e) => {
                            setLoading(true);
                            savePanel(auth.token, e.target.value, content.length, version.current, content, shared.current, panelId,
                                () => { setLoading(false); setEdited(false) }, () => { setLoading(false) })

                        }} />

                </div>
                {content && content.map && content.map((obj, index, oldContent) => Gene(obj, index, oldContent, setContent,

                    (newContent?: any) => {
                        //newContent
                        setLoading(true);
                        savePanel(auth.token, panelName, newContent ? newContent.length : content.length, version.current, newContent ? newContent : content, shared.current, panelId,
                            () => { setLoading(false) }, () => { setLoading(false) })
                    },
                    setEdited)
                )}
                <div id="PanelMenu">
                    <button onClick={() => {
                        let newContent = copyObject(content);
                        newContent.push({
                            "name": "",
                            "id": "",
                            "type": "0",
                            "range": {
                                "chr": "0",
                                "start": 0,
                                "end": 0
                            },
                            "content": {
                                "known": []
                            }
                        });
                        setContent(newContent);
                        setLoading(true);
                        savePanel(auth.token, panelName, newContent ? newContent.length : content.length, version.current, newContent ? newContent : content, shared.current, panelId,
                            () => { setLoading(false) }, () => { setLoading(false) })
                    }}>ADD GENE</button>
                    <div onClick={() => {
                        if (window.confirm("Do you REALLY want to erase all contents of this panel?")) {
                            axios.post(BASE_URL + "panels/delete?id=" + panelId, {}, configureJsonHeader(auth.token))
                                .then((response) => {
                                    if (response.data && response.data.success && response.data.success === true) {
                                        //success
                                        alert("Completely erased.");
                                        navigate("/");
                                    } else {
                                        alert('There was an error! Please try again');
                                    }

                                }).catch((e) => {
                                    // alert(e);
                                    alert('There was an error! Please try again');

                                });

                        }
                    }}>Remove this panel completely</div>
                </div>

            </div>
        </div>
    )

}


function Gene(
    data: any, /* each gene data */
    index: number, /* index of this gene */
    content: any, /* total content of this panel */
    setContent: any, /* for updating state without saving */
    saveContent: (newContent?: any) => void, /* for saving to database */
    setEdited?: (edited: boolean) => void,
) {
    const rangeText = data.range ? composeRangeText(data.range.chr, data.range.start, data.range.end) : "";

    // console.log(content);

    return (
        <div key={index} className='PanelItem'>
            <div className='title-row'>
                <div className='title-left'>
                    <div className='input-title'>GENE NAME</div>
                    <input type="text" onKeyDown={autoReleaseOnEnter} value={data.name}
                        placeholder='Search for gene name'
                        onChange={(e) => {
                            if (setEdited)
                                setEdited(true);
                            setContent(changeContent(content, index, "name", e.target.value));
                        }}
                        onBlur={(e) => {
                            saveContent();
                            retrieveGeneInfo(e.target.value,
                                (symbol: string, id: string, chr: string, start: number, end: number) => {
                                    /* save new info to content */
                                    let newContent = changeContent(changeContent(changeContent(content, index, "range", { "chr": chr, "start": start, "end": end }), index, "name", symbol), index, "id", id);
                                    setContent(newContent);
                                    saveContent(newContent);
                                    if (setEdited)
                                        setEdited(false);

                                }, (msg: string) => {
                                    alert(msg);
                                });

                        }} />
                    <div className={'gene-description'}>{rangeText}</div>
                </div>
                <div className='title-right'>
                    <div className='input-title'>TYPE</div>
                    <select value={data.type} onChange={(e) => {
                        var confirmed = true;
                        if (data.type === "0" && data.content.known && data.content.known.length > 0) {
                            confirmed = window.confirm("Do you really want to switch to genotyping? All saved known mutations will be erased!");
                        } else if (data.type === "1" && data.content && data.content.length > 0) {
                            confirmed = window.confirm("Do you really want to switch to mutation? All saved genotyping information will be erased!");
                        }
                        if (confirmed) {
                            const newContent = changeContent(changeContent(content, index, "type", e.target.value), index, "content", e.target.value === "1" ? [] : { "known": [] });
                            setContent(newContent);
                            saveContent(newContent);
                        }
                    }}>
                        <option value={0}>Mutation</option>
                        <option value={1}>Genotyping</option>
                    </select>

                </div>
            </div>

            {data.type === "0" && <BodyForMutation data={data} index={index} content={content} saveGeneContent={(newContent: any) => {
                // newGeneContent
                // new Content -> content of mutations or genotypes (inside gene)
                const editedContent = changeContent(content, index, "content", newContent);
                setContent(editedContent);
                saveContent(editedContent);
            }} />}
            {data.type === "1" && <BodyForGenotype data={data} geneIndex={index} content={content}
                setGeneContent={(newContent: any) => {
                    if (setEdited)
                        setEdited(true);
                    const editedContent = changeContent(content, index, "content", newContent);
                    setContent(editedContent);

                }}
                saveGeneContent={(newContent?: any) => {
                    // newGeneContent
                    // new Content -> content of mutations or genotypes (inside gene)
                    if (newContent) {
                        const editedContent = changeContent(content, index, "content", newContent);
                        setContent(editedContent);
                        saveContent(editedContent);
                        if (setEdited)
                            setEdited(false);
                    } else {
                        saveContent();
                        if (setEdited)
                            setEdited(false);
                    }
                }}
                saveContent={(newContent: any) => {
                    setContent(newContent);
                    saveContent(newContent);
                }}
            />}

            {data.type === "1" && <GenotypeInterpretations
                interpretations={content[index].interpretations}
                genotypes={content[index].content}
                setInterpretations={(newInterpretations: any) => {
                    const editedContent = changeContent(content, index, "interpretations", newInterpretations);
                    setContent(editedContent);
                    saveContent(editedContent);
                }}
            />}
            {data.type === "1" && <div className='GenotypeReference'>
                <div className='input-title'>REFERENCE LINK</div>
                <input type="text" placeholder='https://' value={data.link ?? ""} onChange={(e) => {
                    if (setEdited)
                        setEdited(true);
                    const newContent = changeContent(content, index, "link", e.target.value);
                    setContent(newContent);
                    // saveContent(newContent);
                }} onBlur={(e) => {
                    const newContent = changeContent(content, index, "link", e.target.value);
                    setContent(newContent);
                    saveContent();
                    if (setEdited)
                        setEdited(false);
                }} onKeyDown={autoReleaseOnEnter} />
            </div>}
            <div className='bottom-row'><div onClick={() => {
                if (window.confirm("Really completely remove this gene from this panel?")) {
                    let newContent = copyObject(content);
                    newContent.splice(index, 1);
                    setContent(newContent);
                    saveContent(newContent);

                }
            }}>Remove gene</div></div>
        </div>
    );
}


function GenotypeInterpretations(props: any) {

    const { interpretations, genotypes, setInterpretations } = props;

    var shownInterpretations: Array<any> = [];
    if (interpretations) {
        shownInterpretations = interpretations;
    }

    /*
    interpretation = {
        hap1 : hap1, (int => index of genotype array)
        hap2 : hap2, (int)
        interpretation : string
    }
    */

    const [interpretationMenu, setInterpretationMenu] = useState(false);

    return <div className='GenotypeInterpretations'>
        <div className='input-title'>INTERPRETATIONS</div>
        <div className='box'>
            {shownInterpretations.length > 0 && <div className='list'>
                {shownInterpretations.map((interpretation, interpretationIndex) => {
                    return <div key={interpretationIndex}>
                        <div className='haplotype'>
                            <span className='title'>
                                TYPE

                            </span>
                            <span className='hapName'>
                                {genotypes[interpretation.hap1].name}
                            </span> /
                            <span className='hapName'>
                                {genotypes[interpretation.hap2].name}
                            </span>
                            <span className='spacer' />
                            <span className='delete' onClick={() => {
                                if (window.confirm("Really erase this interpretation?")) {
                                    let newInterpretations = copyObject(shownInterpretations);
                                    newInterpretations.splice(interpretationIndex, 1);
                                    setInterpretations(newInterpretations);

                                }

                            }}>
                                delete
                            </span>
                        </div>
                        <div className='interpretation'>
                            {interpretation.interpretation}

                        </div>
                    </div>
                })}
            </div>}
            {shownInterpretations.length === 0 && <div className='empty'>
                To add interpretations, use the button below.
            </div>}

        </div>
        <button onClick={(e) => { addKnownMutation(e, setInterpretationMenu) }}>ADD INTERPRETATION</button>
        <button className='gray' onClick={(e) => {
            if (window.confirm("Really remove all interpretations?")) {
                setInterpretations([]);
            }

        }}>REMOVE ALL INTERPRETATIONS</button>
        <InterpretationModal
            display={interpretationMenu}
            setDisplay={setInterpretationMenu}
            addInterpretation={(newInterpretation: any) => {
                /* newMutation should be
                    {
                        "hap1" : int,
                        "hap2" : int,
                        "interpretation" : interpretation
                    }
                */
                let newInterpretations = shownInterpretations;
                newInterpretations.push(newInterpretation);
                setInterpretations(newInterpretations);
            }}
            genotypes={genotypes}
            interpretations={interpretations}
        />
    </div>;
}

// get gene info from Mygene.info
function retrieveGeneInfo(gene: string, success: (symbol: string, id: string, chr: string, start: number, end: number) => void, error: (msg: string) => void) {
    axios.get(`https://mygene.info/v3/query?q=${gene}&species=human`)
        .then((response) => {
            if (response.status === 200 && response.data.total > 0) {
                //successful data
                //get id
                const id: string = response.data.hits[0]["entrezgene"];
                const symbol: string = response.data.hits[0]['symbol'];
                axios.get(`https://mygene.info/v3/gene/${id}?fields=genomic_pos`)
                    .then((response2) => {
                        if (response2.status === 200 && response2.data.genomic_pos) {
                            const chr: string = response2.data.genomic_pos.chr;
                            const start: number = response2.data.genomic_pos.start;
                            const end: number = response2.data.genomic_pos.end;
                            success(symbol, id, chr, start, end);
                        } else {
                            error("Could not find gene information");
                        }

                    }).catch(e => {
                        console.warn(e);
                    });

            } else if (response.status === 200) {
                //success but no result
                error("Could not find gene");
            }
        }).catch((e) => {
            console.warn(e);
        })

}


function composeRangeText(chr: string, start: number, end: number) {
    return `CHR ${chr} : ${start} - ${end}`;
}




function BodyForMutation(props: any) {

    const [mutationMenu, setMutationMenu] = useState({
        "shown": false,
        "x": 0.0,
        "y": 0.0
    });

    const { data, index, content, saveGeneContent } = props;

    var mutations;
    if (data.content) {
        if (data.content.known) {
            mutations = data.content.known;
        }
    }
    if (!mutations) mutations = [];


    var output;
    if (mutations.length === 0) {
        output = <div className='info'>
            To add known mutations, use the button below.
        </div>;

    } else {
        // console.log(mutations);
        output = <div className='list'>
            {mutations.map((obj: any, index: number) => <div key={index}><SequenceBlock chr={obj.chr} loc={obj.loc} seq={obj.seq} description={obj.description} remove={() => {
                //remove this object
                let newContent = JSON.parse(JSON.stringify(data.content));
                newContent.known.splice(index, 1);
                saveGeneContent(newContent);
            }} /></div>)}
        </div>

    }

    return (
        <div className='mutations'>
            <div className='input-title'>
                KNOWN MUTATIONS
            </div>
            <div className='mutation-box'>
                {output}


                <div className='mutation-bottom'>
                    <button className={mutationMenu.shown ? 'activated' : ''} onClick={(e) => addKnownMutation(e, setMutationMenu)}>ADD KNOWN MUTATION</button>
                    <button className='gray' disabled={mutations.length === 0} onClick={() => {
                        if (window.confirm("Really delete all known mutations?")) {
                            let newContent = JSON.parse(JSON.stringify(data.content));
                            newContent.known = [];
                            saveGeneContent(newContent);

                        }
                    }}>REMOVE ALL</button>
                </div>
            </div>
            <MutationModal
                range={data.range}
                display={mutationMenu}
                setDisplay={setMutationMenu}
                addMutation={(newMutation: any) => {
                    /* newMutation should be
                        {
                            "chr" : string,
                            "loc" : string,
                            "seq" : string,
                            "description" : string
                        }
                    */
                    let newContent = JSON.parse(JSON.stringify(data.content));
                    newContent.known.push({
                        "chr": newMutation.chr,
                        "loc": newMutation.loc,
                        "seq": newMutation.seq,
                        "description": newMutation.description
                    });
                    saveGeneContent(newContent);
                    /* known should be an array */

                }} />
        </div>

    );
}

function BodyForGenotype(props: any) {
    const { data, geneIndex, content, setGeneContent, saveGeneContent, saveContent } = props;
    /* 
        set Gene Content just sets content and updates state
        save Gene Content saves to DB with optionally setting content and updating state 
    */

    var output;

    if (data.content) {
        if (data.content.length > 0) {
            output = data.content.map((element: any, index: number) => <Genotype key={index} data={element} setData={(newData: any) => {
                let oldContent = JSON.parse(JSON.stringify(data.content));
                oldContent[index] = newData;
                setGeneContent(oldContent);
            }} saveData={(newData?: any) => {
                if (newData) {
                    let oldContent = JSON.parse(JSON.stringify(data.content));
                    oldContent[index] = newData;
                    saveGeneContent(oldContent);
                } else {
                    saveGeneContent();
                }

            }} removeGenotype={() => {
                let oldContent = JSON.parse(JSON.stringify(data.content));
                oldContent.splice(index, 1);
                /*
                    adjust interpretation
                    1. remove any interpretation with included index in either hap
                    2. reduce hap by one if hap larger than index
                */
                console.log();
                let editedContent = changeContent(content, geneIndex, "content", oldContent);
                if (data.interpretations) {
                    console.log(data.interpretations);
                    let oldInterpretations = copyObject(data.interpretations);
                    let newInterpretations = oldInterpretations.filter((interpretation: any) => {
                        if ((interpretation.hap1 === index) || (interpretation.hap2 === index)) {
                            return false;

                        }
                        return true;
                    });
                    for (let i = 0; i < newInterpretations.length; i++) {
                        if (newInterpretations[i].hap1 > index) {
                            newInterpretations[i].hap1 -= 1;
                        }

                        if (newInterpretations[i].hap2 > index) {
                            newInterpretations[i].hap2 -= 1;
                        }
                    }
                    editedContent = changeContent(editedContent, geneIndex, "interpretations", newInterpretations);
                }

                saveContent(editedContent);
                // saveGeneContent(oldContent);

            }} range={data.range} />);
        } else {
            //no genotypes set
            output = <div className='empty'>
                Add genotypes using the ADD GENOTYPE button below.
            </div>;
        }
    }

    return (<div className='genotypes'>
        <div className='input-title'>
            GENOTYPES
        </div>
        {output}
        <button onClick={(e) => {

            let oldContent = JSON.parse(JSON.stringify(data.content));
            oldContent.push({ "name": "", "mutations": "" });
            saveGeneContent(oldContent);

        }}>ADD GENOTYPES</button>
        <button className='gray' onClick={() => {
            if (window.confirm("Really remove all genotypes?")) {
                saveGeneContent([]);
            }
        }}>REMOVE ALL GENOTYPES</button>
    </div>);
}


function Genotype(props: any) {

    const { data, setData, saveData, removeGenotype, range } = props;
    /* data looks like this :

        "name" : "genotype name",
        "mutations" : [
            {
                "chr" : 10,
                "loc" : 2102312,
                "seq" : "A",
                
            }
        ],

    */

    const [mutationMenu, setMutationMenu] = useState({
        "shown": false,
        "x": 0.0,
        "y": 0.0
    });

    var mutations: any[];
    if (data && data.mutations) {
        mutations = data.mutations;
    } else {
        mutations = [];

    }


    var output;
    if (mutations.length === 0) {
        output = <div className='info'>
            To add mutations consisting this genotype, use the button below.
        </div>;

    } else {
        // console.log(mutations);
        output = <div className='list'>
            {mutations.map((obj: any, index: number) => <div key={index}><SequenceBlock chr={obj.chr} loc={obj.loc} seq={obj.seq} hideDescription={true} remove={() => {
                //remove this object
                let newData = JSON.parse(JSON.stringify(data));
                newData.mutations.splice(index, 1);
                saveData(newData);
            }} /></div>)}
        </div>

    }

    const [hidden, setHidden] = useState(true);

    return <div className={'Genotype' + (hidden ? " hidden" : "")} onClick={() => {
        if (hidden) {
            setHidden(false);
        }
    }}>
        <div className='title-row'>
            <input type="text" disabled={hidden} placeholder='Genotype Name' onKeyDown={autoReleaseOnEnter} value={data.name}
                onChange={(e) => {
                    let newData = copyObject(data);
                    newData.name = e.target.value;
                    setData(newData);
                }}
                onBlur={(e) => {
                    saveData();
                }}
            />
            <div className='remove' onClick={() => {
                if (window.confirm("Do you really want to remove this genotype?"))
                    removeGenotype();

            }}>Remove genotype</div>
            <div className='toggle' onClick={() => {
                setHidden(!hidden);
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15.637" height="9.318" viewBox="0 0 15.637 9.318">
                    <path id="caret" data-name="Path 193" d="M6425.488,1186l5.7,5.7,5.7-5.7" transform="translate(-6423.367 -1183.879)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                </svg>

            </div>
        </div>
        <div className='body'>
            {output}
            <div className='mutation-bottom'>
                <button className={mutationMenu.shown ? 'activated' : ''} onClick={(e) => addKnownMutation(e, setMutationMenu)}>ADD MUTATION</button>
                <button className='gray' disabled={mutations.length === 0} onClick={() => {
                    if (window.confirm("Really delete all known mutations?")) {
                        let newData = JSON.parse(JSON.stringify(data));
                        newData.mutations = [];
                        saveData(newData);

                    }
                }}>REMOVE ALL</button>
            </div>
        </div>
        <MutationModal
            range={range}
            display={mutationMenu}
            setDisplay={setMutationMenu}
            addMutation={(newMutation: any) => {
                /* newMutation should be
                    {
                        "chr" : string,
                        "loc" : string,
                        "seq" : string,
                        "description" : string
                    }
                */
                let newData = JSON.parse(JSON.stringify(data));
                mutations.push({
                    "chr": newMutation.chr,
                    "loc": newMutation.loc,
                    "seq": newMutation.seq,
                    "description": newMutation.description
                });
                newData.mutations = mutations;
                saveData(newData);
                /* known should be an array */

            }}
            isGenotyping={true} />
    </div>;

}


function addKnownMutation(
    event: any,
    setDisplay: any
) {
    const bottomSpacer = 60;
    const modalHeight = 160;
    const topSpacer = 5;

    const bodyRect = document.body.getBoundingClientRect();
    const rect = event.target.getBoundingClientRect();
    var absoluteY = rect.y - bodyRect.y + rect.height + topSpacer;
    const absoluteX = rect.x - bodyRect.x;


    if ((absoluteY + modalHeight + bottomSpacer) > document.body.scrollHeight) {
        //too large, so adjust Y
        absoluteY = document.body.scrollHeight - (bottomSpacer + modalHeight);
    }


    setDisplay({
        "shown": true,
        "x": absoluteX,
        "y": absoluteY
    });


}

function MutationModal(props: any) {
    const { range, display, setDisplay, addMutation, isGenotyping } = props;

    let initialChr = "", start = 0, end = 0;


    const [chr, setChr] = useState("");
    const [loc, setLoc] = useState("");
    const [seq, setSeq] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (range) {
            if (range.chr) {
                initialChr = range ? range.chr : "";
                setChr(initialChr);
            }
        }
    }, [range])


    if (range.start) {
        start = range.start;
    }
    if (range.end) {
        end = range.end;
    }

    // var int_loc;
    // try {
    //     int_loc = parseInt(loc.toString());
    // } catch(e) {
    //     int_loc = 0;
    // }
    // if (loc < start) {

    // }

    const SubmitButton = <div className='submit-button'>
        {/* submit : 2 */}

        <button onClick={(e) => {
            submitNewMutation(
                description,
                initialChr,
                chr, loc, seq,
                start, end, isGenotyping,
                addMutation, setChr, setLoc, setSeq, setDescription
            );


        }} >Add mutation</button>
    </div >;


    return (
        <div>
            <div className='mutation-modal-background'
                style={{
                    "visibility": display.shown ? "visible" : "hidden",
                    "opacity": display.shown ? 1 : 0,
                }}
                onClick={(e) => {
                    setDisplay({
                        "shown": false, "x": display.x, "y": display.y
                    });
                }}
            >

            </div>
            <div className={'mutation-modal' + (isGenotyping ? " genotyping" : "")}
                style={{
                    "visibility": display.shown ? "visible" : "hidden",
                    "opacity": display.shown ? 1 : 0,
                    "top": display.y,
                    "left": display.x
                }}>
                <div>
                    <div>
                        {/* first column */}
                        <div >
                            {/* chromosome : 2 */}
                            <span>Chromosome</span>
                            <input disabled={initialChr === "" ? false : true} type="text" placeholder="1-22, X, Y" value={chr} onChange={(e) => { setChr(e.target.value) }} />
                        </div>
                        <div className='loc'>
                            {/* location : 3 */}
                            <span>Location</span>
                            <input type="number" placeholder={start > 0 ? `${range.start} - ${range.end}` : "ex) 12039201"} value={loc} onChange={(e) => { setLoc(e.target.value) }}
                                onKeyDown={(e) => {
                                    if (e.key == "Enter") {
                                        submitNewMutation(
                                            description,
                                            initialChr,
                                            chr, loc, seq,
                                            start, end, isGenotyping,
                                            addMutation, setChr, setLoc, setSeq, setDescription
                                        );
                                    }
                                }}
                            />
                        </div>
                        <div >
                            {/* sequence : 2*/}
                            <span>Sequence</span>
                            <input type="text" placeholder='ex) ATGC' value={seq} onChange={(e) => {
                                const newValue = e.target.value.toUpperCase().trim();
                                if (newValue === "" || /^[ATGCU]+$/.test(newValue)) {
                                    setSeq(newValue)

                                }
                            }} onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    submitNewMutation(
                                        description,
                                        initialChr,
                                        chr, loc, seq,
                                        start, end, isGenotyping,
                                        addMutation, setChr, setLoc, setSeq, setDescription
                                    );
                                }
                            }} />
                        </div>
                        {isGenotyping && SubmitButton}
                    </div>
                    {!isGenotyping &&
                        <div>
                            {/* second column */}
                            <div className='description'>
                                {/* description : 5 */}
                                <span>Description</span>
                                <input type="text"
                                    placeholder='Short description'
                                    value={description}
                                    onChange={(e) => { setDescription(e.target.value) }}
                                    onKeyDown={(e) => {
                                        if (e.key == "Enter") {
                                            submitNewMutation(
                                                description,
                                                initialChr,
                                                chr, loc, seq,
                                                start, end, isGenotyping,
                                                addMutation, setChr, setLoc, setSeq, setDescription
                                            );
                                        }
                                    }} />
                            </div>
                            {SubmitButton}
                        </div>}
                </div>

            </div>
        </div>
    )
}

function submitNewMutation(
    description: string,
    initialChr: string,
    chr: string, loc: string, seq: string,
    start: number, end: number, isGenotyping: boolean,
    addMutation: any, setChr: any, setLoc: any, setSeq: any, setDescription: any
) {
    // let refs = description.split(",").map(each => each.trim());
    // if (refs.length === 1 && refs[0] === "") refs = [];

    if (chr.trim() === "" || loc.trim() === "" || seq.trim() === "") {
        alert("Chromosome, location and sequence are required");
        return;
    }

    var intLoc = 0;
    try {
        intLoc = parseInt(loc);
    } catch (e) {
        alert("The value of location should be an integer");
        return;
    }

    if (start >= 0 && intLoc < start) {
        if (isGenotyping) {
            if (!window.confirm(`The location (${loc}) is smaller than the start location of gene (${start}). Continue?`)) {
                return;
            }
        } else {
            alert(`The location (${loc}) is smaller than the start location of gene (${start})`);
            return;
        }
    }

    if (end >= 0 && intLoc > end) {
        if (isGenotyping) {
            if (!window.confirm(`The location (${loc}) is larger than the end location of gene (${end}). Continue?`)) {
                return;
            }
        } else {
            alert(`The location (${loc}) is larger than the end location of gene (${end})`);
        }
        return;
    }


    addMutation({
        "chr": chr,
        "loc": loc,
        "seq": seq,
        "description": description
    });
    setChr(initialChr);
    setLoc("");
    setSeq("");
    setDescription("");
    // setDisplay({
    //     "shown": false, "x": display.x, "y": display.y
    // });
}


function InterpretationModal(props: any) {
    const { genotypes, interpretations, display, setDisplay, addInterpretation } = props;


    const [interpretation, setInterpretation] = useState("");
    const [hap1, setHap1] = useState(-1);
    const [hap2, setHap2] = useState(-1);

    const [haplotypeList, setHaplotypeList] = useState([]);
    const [disableList, setDisableList] = useState<Array<number>>([]);


    /*
    interpretation object : { hap1, hap2, interpretation }

    */

    useEffect(() => {
        // TODO : make sets
        if (genotypes)
            setHaplotypeList(genotypes.map((genotype: any) => genotype.name ?? ""));
    }, [genotypes]);

    useEffect(() => {
        //check interpretations
        let newDisabledList: Array<number> = [];
        if (interpretations)
            interpretations.forEach((eachInterpretation: any) => {
                if (eachInterpretation.hap1 === hap1) {
                    newDisabledList.push(eachInterpretation.hap2);
                } else if (eachInterpretation.hap2 === hap1) {
                    newDisabledList.push(eachInterpretation.hap1);
                }
            });

        setDisableList(newDisabledList);
    }, [hap1]);


    const SubmitButton = <div className='submit-button'>
        {/* submit : 2 */}

        <button onClick={(e) => {

            submitNewInterpretations(
                addInterpretation, setInterpretation, hap1, hap2, interpretation, setHap1, setHap2
            );

        }} >Add</button>
    </div>;


    return (
        <div>
            <div className='mutation-modal-background'
                style={{
                    "visibility": display.shown ? "visible" : "hidden",
                    "opacity": display.shown ? 1 : 0,
                }}
                onClick={(e) => {
                    setDisplay({
                        "shown": false, "x": display.x, "y": display.y
                    });
                }}
            >

            </div>
            <div className={'mutation-modal'}
                style={{
                    "visibility": display.shown ? "visible" : "hidden",
                    "opacity": display.shown ? 1 : 0,
                    "top": display.y,
                    "left": display.x
                }}>
                <div>
                    <div>
                        {/* first column */}
                        <div className='haplotype'>
                            <span>Haplotype 1</span>
                            <select value={hap1} onChange={(e) => {
                                setHap1(parseInt(e.target.value));
                            }}>
                                <option value="-1" key="-1">
                                    Select haplotype...
                                </option>
                                {haplotypeList.map((hap, hapIndex) => <option key={hapIndex} value={hapIndex}>
                                    {hap === "" ? "Untitled" : hap}
                                </option>)}
                            </select>
                        </div>
                        <div className='haplotype'>
                            <span>Haplotype 2</span>
                            <select value={hap2} onChange={(e) => {
                                setHap2(parseInt(e.target.value));
                            }}>
                                <option value="-1">
                                    Select haplotype...
                                </option>
                                {haplotypeList.map((hap, hapIndex) => <option key={hapIndex} value={hapIndex} disabled={disableList.includes(hapIndex)}>
                                    {hap === "" ? "Untitled" : hap}
                                </option>)}
                            </select>
                        </div>
                    </div>

                    <div>
                        {/* second column */}
                        <div className='interpretation'>
                            {/* description : 5 */}
                            <span>Interpretation</span>
                            <input type="text"
                                placeholder='Interpretation'
                                value={interpretation}
                                onChange={(e) => { setInterpretation(e.target.value) }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        submitNewInterpretations(
                                            addInterpretation, setInterpretation, hap1, hap2, interpretation, setHap1, setHap2
                                        );
                                    }
                                }}
                            />
                        </div>
                        {SubmitButton}
                    </div>
                </div>

            </div>
        </div>
    )
}
function submitNewInterpretations(addInterpretation: any, setInterpretation: any, hap1: number, hap2: number, interpretation: String, setHap1: any, setHap2: any) {

    addInterpretation({
        "hap1": hap1,
        "hap2": hap2,
        "interpretation": interpretation
    });
    setInterpretation("");
    setHap1(-1);
    setHap2(-1);
}