import './PanelIcon.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export function PanelIcon(props: any) {
    // requires props.panel
    /* panel structure
        content : object
        created: "2021-12-26T11:28:58"
        edited: "2021-12-26T11:28:58"
        gene_count: 0
        id: 24
        name: ""
        original_id: 0
        original_owner_id: 0
        owner_id: 2
        shared: 0
        version: 1

    */
   //requires props.onQrCode();
    const navigate = useNavigate();

    let displayName = "Untitled";
    let namePresent= false;
    if(props.panel.name && props.panel.name !== "") {
        namePresent= true;
        displayName = props.panel.name;
    }

    const dateString = new Date(props.panel.edited).toLocaleDateString();

    return (
        <div className='PanelIcon' onClick={() => { navigate("/edit/"+props.panel.id); }}>
            <div>
                <div>
                    <div className='info'>
                        PANEL NAME
                    </div>
                    <div className={'title' + (namePresent ? "" : " gray")}>
                        {displayName }

                    </div>

                </div>


                <div>
                    <div className='info'>
                        GENE COUNT
                    </div>
                    <div>
                        {props.panel.gene_count}

                    </div>

                </div>
                <div className='bottom'>
                    <div>
                        <div className='info'>
                            LAST EDITED
                        </div>
                        <div>
                            {dateString}

                        </div>
                    </div>
                    <div className='qrcode' onClick={(event) => {
                        event.stopPropagation();

                        if(props.onQrCode) {
                            props.onQrCode();
                        }
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30.849 30.849">
                            <path id="Icon_metro-qrcode" data-name="Icon metro-qrcode" d="M12.211,3.856H4.5v7.712h7.712Zm1.928-1.928V13.5H2.571V1.928ZM6.427,5.784h3.856V9.64H6.427ZM31.491,3.856H23.779v7.712h7.712V3.856Zm1.928-1.928V13.5H21.851V1.928H33.419ZM25.707,5.784h3.856V9.64H25.707Zm-13.5,17.352H4.5v7.712h7.712Zm1.928-1.928V32.777H2.571V21.208ZM6.427,25.064h3.856v3.856H6.427Zm9.64-23.136h1.928V3.856H16.067Zm1.928,1.928h1.928V5.784H17.995ZM16.067,5.784h1.928V7.712H16.067Zm1.928,1.928h1.928V9.64H17.995ZM16.067,9.64h1.928v1.928H16.067Zm1.928,1.928h1.928V13.5H17.995ZM16.067,13.5h1.928v1.928H16.067Zm0,3.856h1.928V19.28H16.067Zm1.928,1.928h1.928v1.928H17.995Zm-1.928,1.928h1.928v1.928H16.067Zm1.928,1.928h1.928v1.928H17.995Zm-1.928,1.928h1.928v1.928H16.067Zm1.928,1.928h1.928v1.928H17.995Zm-1.928,1.928h1.928v1.928H16.067Zm1.928,1.928h1.928v1.928H17.995Zm13.5-13.5h1.928V19.28H31.491Zm-26.992,0H6.427V19.28H4.5Zm1.928-1.928H8.355v1.928H6.427Zm-3.856,0H4.5v1.928H2.571Zm7.712,0h1.928v1.928H10.283Zm1.928,1.928h1.928V19.28H12.211Zm1.928-1.928h1.928v1.928H14.139Zm5.784,1.928h1.928V19.28H19.923Zm1.928-1.928h1.928v1.928H21.851Zm1.928,1.928h1.928V19.28H23.779Zm1.928-1.928h1.928v1.928H25.707Zm1.928,1.928h1.928V19.28H27.635Zm1.928-1.928h1.928v1.928H29.563Zm1.928,5.784h1.928v1.928H31.491Zm-11.568,0h1.928v1.928H19.923Zm1.928-1.928h1.928v1.928H21.851Zm1.928,1.928h1.928v1.928H23.779Zm3.856,0h1.928v1.928H27.635Zm1.928-1.928h1.928v1.928H29.563Zm1.928,5.784h1.928v1.928H31.491Zm-11.568,0h1.928v1.928H19.923Zm1.928-1.928h1.928v1.928H21.851Zm3.856,0h1.928v1.928H25.707Zm1.928,1.928h1.928v1.928H27.635Zm1.928-1.928h1.928v1.928H29.563Zm1.928,5.784h1.928v1.928H31.491Zm-9.64-1.928h1.928v1.928H21.851Zm1.928,1.928h1.928v1.928H23.779Zm1.928-1.928h1.928v1.928H25.707Zm1.928,1.928h1.928v1.928H27.635Zm-5.784,1.928h1.928v1.928H21.851Zm3.856,0h1.928v1.928H25.707Zm3.856,0h1.928v1.928H29.563Z" transform="translate(-2.571 -1.928)" />
                        </svg>

                    </div>


                </div>

            </div>
        </div>
    );

}